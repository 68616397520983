@keyframes chat-messages__typing-indicator__fade {
    from {
        opacity: 0.75;
    }

    to {
        opacity: 0.25;
    }
}

@mixin message-typing($delay) {
    opacity: 0.35;
    animation: chat-messages__typing-indicator__fade 0.7s cubic-bezier(0.39, 0.58, 0.57, 1) $delay infinite
        alternate-reverse;
}

@keyframes chat-messages__typing-indicator__appear {
    from {
        opacity: 0;
        transform: translateX(10px);
    }
}

.chat-messages__typing-indicator {
    $size: 10px;
    margin-right: auto;
    animation: chat-messages__typing-indicator__appear 250ms ease-out;

    // The status message is for accessibilty only.
    &__status {
        display: none;
    }

    div {
        display: inline-block;
        height: $size;
        width: $size;
        border-radius: $size;
    }

    div:nth-of-type(1) {
        @include message-typing(0s);
    }

    div:nth-of-type(2) {
        @include message-typing(400ms);
    }

    div:nth-of-type(3) {
        @include message-typing(800ms);
    }
}
