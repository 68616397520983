@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/variables";

.select-menu {
    padding: spacing.$spacing-large;

    h2 {
        text-transform: capitalize;
    }

    &__input {
        margin-top: spacing.$spacing-base;
    }

    &--is-not-searchable &__input {
        display: none;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
    }

    &__options {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: 350px;
        scroll-behavior: smooth;
        border: 1px solid colours.$divider;
        border-radius: 4px;

        ul {
            flex: 1;
            scroll-behavior: smooth;
            overflow-y: auto;
            > li:first-child {
                .select-menu__group-title {
                    border-top: 0;
                    margin-top: 0;
                }
            }
        }
    }

    .select__menu--is-compact &__options {
        max-height: 135px;
    }

    &--is-searchable &__lists {
        padding: spacing.$spacing-x-large 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &--is-searchable &__options {
        box-shadow: inset 0 1px 3px rgba(black, 0.1);
    }

    .select-list-title + &__options {
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &__group-title {
        font-size: variables.$font-size-small;
        padding: spacing.$spacing-large;
        font-weight: 700;
        color: colours.$primary;
        text-transform: uppercase;
        border-top: 1px solid colours.$divider;
        margin-top: spacing.$spacing-base;
    }

    &__list--not-selected &__option {
        svg {
            margin-left: spacing.$spacing-small;
        }
    }

    &__list--is-selected &__option {
        svg {
            margin-right: spacing.$spacing-small;
        }

        &__label {
            text-align: right;
        }
    }

    &__list--is-selected &__group-title {
        text-align: right;
    }

    &__options--empty {
        color: colours.$label;
        font-style: italic;
        text-align: center;
        padding: spacing.$spacing-large;
    }

    &__option {
        cursor: pointer;
        transition: background-color 250ms ease-out;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & + & {
            border-top: 1px solid colours.$background-tertiary;
        }

        &__label {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: variables.$line-height-base;
            max-width: 320px;
            white-space: nowrap;
        }

        > svg {
            color: colours.$label;
        }
    }

    &__option,
    .select-bulk-buttons,
    &__message {
        padding: spacing.$spacing-large;
    }

    &__option:hover,
    &__option--is-highlighted {
        background-color: colours.$background-tertiary;
    }

    &__option--is-disabled {
        pointer-events: none;
        color: colours.$flat-asbestos;
        opacity: 0.5;
    }

    &--is-multiple-select &__lists {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
    }

    &__message {
        background-color: colours.$background-secondary;
        border-bottom: 1px solid colours.$divider;
        font-weight: bold;
        font-size: variables.$font-size-small;
        color: colours.$label;
        box-shadow: inset 0 1px 2px rgba(black, 0.1);
        svg {
            margin-right: spacing.$spacing-x-small;
        }
    }

    &__add-item {
        margin-top: spacing.$spacing-large;

        button {
            width: 100%;
        }
    }
}
