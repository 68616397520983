@use "variables";
@use "spacing";
@use "colours";

@use "react-redux-toastr/src/styles/index";

.redux-toastr .toastr {
    $icon-width: 60px;
    $line-height: 18px;
    min-height: $line-height * 2;
    border-radius: 2px;

    .rrt-middle-container {
        margin-left: $icon-width + spacing.$spacing-base;
        .rrt-title,
        .rrt-text {
            line-height: $line-height;
        }
    }
    .rrt-left-container,
    .rrt-left-container .rrt-holder {
        width: $icon-width;
    }
    .close-toastr {
        color: white;
        font-size: variables.$font-size-base;
    }
}
