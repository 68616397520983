@use "spacing";
@use "variables";
@use "colours";

html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
}

body {
    font-size: variables.$font-size-base;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
    color: colours.$label;
}

h5 {
    font-size: 1.4rem;
    font-weight: bold;
}

h3 + h4 {
    margin-top: -10px;
}

p {
    margin-top: spacing.$spacing-base;
    line-height: variables.$line-height-base;
}

strong {
    font-weight: bold;
}

input,
textarea,
select,
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    color: colours.$color;
}

h1,
h2,
h3,
h4 {
    letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4 {
    font-weight: 800;
}

ul.list {
    list-style-type: disc;
    padding-left: 30px;
    li {
        margin-top: spacing.$spacing-small;
    }
}

a {
    text-decoration: none;
    color: inherit;
}
