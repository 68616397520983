@use "styles/theme";
@use "styles/spacing";
@use "styles/variables";

.query-history-note {
    margin: spacing.$spacing-x-large auto;
    border-radius: variables.$box-border-radius;
    box-shadow: variables.$box-box-shadow;
    max-width: 60%;

    @include theme.theme {
        background-color: theme.get(background);
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__header,
    .markdown {
        @include theme.theme {
            border-bottom: 1px solid theme.get(divider);
        }
    }

    .query-history-note-controls,
    &__header,
    .markdown {
        padding: spacing.$spacing-x-large;
    }
}
