@use "styles/colours";
@use "styles/spacing";
@use "styles/theme";

.net-promoter-score-item {
    &:not(.form-submission-net-promoter-score-field__value--selected)::after {
        opacity: 0.65;
        height: 4px;
        width: 4px;
    }

    border-radius: 6px;
    flex: 1;
    padding: spacing.$spacing-xx-small 0;
    white-space: nowrap;

    &::after {
        content: "";
        margin-top: spacing.$spacing-xx-small;
        margin-left: auto;
        margin-right: auto;
        display: block;
        border-radius: 100%;

        @include theme.theme {
            background-color: theme.get(negative);
        }
    }

    &--selected {
        font-weight: bold;
        opacity: 1;
        font-size: 18px;

        &::after {
            height: 8px;
            width: 8px;
        }
    }

    &:nth-of-type(7)::after,
    &:nth-of-type(8)::after {
        @include theme.theme {
            background-color: colours.$flat-sunflower;
        }
    }

    &:nth-of-type(9)::after,
    &:nth-of-type(10)::after {
        @include theme.theme {
            background-color: theme.get(positive);
        }
    }
}
