@use "styles/spacing";

.chat-events-filters {
    display: flex;

    .radio {
        width: auto;
        label {
            padding: 0;
        }
    }
    .radio + .radio {
        margin-left: spacing.$spacing-x-large;
    }
}

.old-modal .chat-events-filters form {
    margin: 0;
    padding: 0;
}
