@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";

.interaction-events {
    position: relative;
    padding: variables.$page-spacing;
    overflow-y: auto;

    &--has-timeline {
        .chat-messages__message,
        .interaction-event {
            position: relative;
        }

        // The messages and any icons need to appear over the timeline.
        .chat-messages__message__container,
        .chat-messages__message > svg {
            position: relative;
            z-index: 2;
        }

        .chat-messages__message::before,
        .chat-messages__message:not(.chat-messages__message--status)::after,
        .interaction-event::before,
        .interaction-event::after {
            position: absolute;
            content: " ";
            z-index: 1;

            @include theme.theme {
                background-color: lighten(theme.get(medium), 10%);
            }
        }

        .chat-messages__message::before,
        .interaction-event::before,
        .interaction-event::after {
            left: 50%;
            margin-right: -0.5px;
            width: 1px;
        }

        .chat-messages__message::before {
            top: 0;
            bottom: 0;
        }

        .interaction-event {
            &::before {
                bottom: 100%;
                height: spacing.$spacing-small;
            }

            &::after {
                top: 100%;
                height: spacing.$spacing-small;
            }
        }

        .chat-messages__message::after {
            height: 1px;
            top: 50%;
            margin-top: -0.5px;
            width: 50%;

            &--out::after {
                left: 50%;
            }

            &--in::after {
                right: 50%;
            }
        }
    }
}
