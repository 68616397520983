@use "colours";

$themes: (
    dark: (
        color: colours.$flat-clouds,
        color-secondary: white,
        background: colours.$flat-wet-asphalt,
        background-secondary: darken(colours.$flat-wet-asphalt, 2%),
        background-tertiary: colours.$flat-midnight-blue,
        divider: darken(colours.$flat-wet-asphalt, 7%),
        label: colours.$flat-concrete,
        medium: colours.$flat-silver,
        pop-up-background: colours.$flat-midnight-blue,
        pop-up-color: colours.$flat-clouds,
        scroll: darken(colours.$flat-midnight-blue, 5%),
        modal-overlay-background:
            linear-gradient(
                to top,
                rgba(darken(colours.$flat-midnight-blue, 10%), 0.2),
                rgba(darken(colours.$flat-midnight-blue, 25%), 0.5)
            ),
        loading-background: white,
        primary: colours.$flat-peter-river,
        primary-variant: colours.$flat-belize-hole,
        positive: colours.$flat-green-sea,
        positive-variant: darken(colours.$flat-green-sea, 5%),
        negative: colours.$flat-alizarin,
        negative-variant: colours.$flat-pomegranate,
        neutral: colours.$flat-asbestos,
        neutral-variant: darken(colours.$flat-asbestos, 10%),
        disabled: darken(colours.$flat-midnight-blue, 5%),
        disabled-color: lighten(colours.$flat-wet-asphalt, 10%),
        alert-background: colours.$flat-pomegranate,
        alert-color: white,
        alert-border: colours.$flat-alizarin,
    ),
    light: (
        color: colours.$flat-midnight-blue,
        color-secondary: colours.$flat-wet-asphalt,
        background: white,
        background-secondary: lighten(colours.$flat-clouds, 2%),
        background-tertiary: colours.$flat-clouds,
        divider: darken(colours.$flat-clouds, 7%),
        label: colours.$flat-asbestos,
        medium: colours.$flat-concrete,
        pop-up-background: colours.$flat-wet-asphalt,
        pop-up-color: white,
        scroll: colours.$flat-concrete,
        modal-overlay-background:
            linear-gradient(to top, rgba(colours.$flat-clouds, 0.2), rgba(colours.$flat-midnight-blue, 0.5)),
        loading-background: colours.$flat-alizarin,
        primary: colours.$flat-peter-river,
        primary-variant: colours.$flat-belize-hole,
        positive: colours.$flat-green-sea,
        positive-variant: darken(colours.$flat-green-sea, 5%),
        negative: colours.$flat-alizarin,
        negative-variant: colours.$flat-pomegranate,
        neutral: colours.$flat-asbestos,
        neutral-variant: darken(colours.$flat-asbestos, 10%),
        disabled: colours.$flat-clouds,
        disabled-color: colours.$flat-silver,
        alert-background: lighten(colours.$flat-alizarin, 37%),
        alert-color: colours.$flat-alizarin,
        alert-border: colours.$flat-alizarin,
    ),
    contrast: (
        color: black,
        color-secondary: colours.$flat-midnight-blue,
        background: white,
        background-secondary: lighten(colours.$flat-silver, 2%),
        background-tertiary: darken(colours.$flat-silver, 2%),
        divider: colours.$flat-silver,
        label: darken(colours.$flat-asbestos, 15%),
        medium: darken(colours.$flat-asbestos, 5%),
        pop-up-background: colours.$flat-wet-asphalt,
        pop-up-color: white,
        scroll: colours.$flat-concrete,
        modal-overlay-background:
            linear-gradient(to top, rgba(colours.$flat-clouds, 0.2), rgba(colours.$flat-midnight-blue, 0.5)),
        loading-background: colours.$flat-alizarin,
        primary: colours.$flat-peter-river,
        primary-variant: colours.$flat-belize-hole,
        positive: colours.$flat-green-sea,
        positive-variant: darken(colours.$flat-green-sea, 5%),
        negative: colours.$flat-alizarin,
        negative-variant: colours.$flat-pomegranate,
        neutral: colours.$flat-asbestos,
        neutral-variant: darken(colours.$flat-asbestos, 10%),
        disabled: colours.$flat-concrete,
        disabled-color: colours.$flat-clouds,
        alert-background: lighten(colours.$flat-alizarin, 40%),
        alert-color: colours.$flat-alizarin,
        alert-border: colours.$flat-alizarin,
    ),
);

@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);

    @if $value != null {
        @if $length > 1 {
            $rest: ();

            @for $i from 2 through $length {
                $rest: append($rest, nth($keys, $i));
            }

            @return map-fetch($value, $rest);
        } @else {
            @return $value;
        }
    } @else {
        @return false;
    }
}

@function get($key) {
    @return map-get($theme-map, $key);
}

@mixin theme($themes: $themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            // Define theme color
            $theme-map: (
                color-alpha: blue,
            ) !global;
            @each $key, $submap in $map {
                $value: map-fetch($themes, $theme "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}
