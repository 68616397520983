@use "variables";
@use "spacing";
@use "colours";

@mixin outline-button($background-color) {
    &:not(.button-disabled):not([disabled]) {
        background-color: transparent;
        color: $background-color;
        border: 1px solid rgba($background-color, 0.6);
        &:hover {
            color: white;
            background-color: $background-color;
        }
        &:active {
            background-color: colours.$color;
        }
    }
    &[data-tooltip],
    &[data-tooltip-left] {
        @keyframes tooltip-appear {
            from {
                transform: scale(0.25);
            }
            to {
                visibility: visible;
            }
        }
        position: relative;
        &:hover:before {
            animation: tooltip-appear 100ms ease-in forwards;
            animation-delay: 750ms;
        }
        &:before {
            display: flex;
            align-items: center;
            visibility: hidden;
            position: absolute;
            font-size: variables.$font-size-small;
            white-space: nowrap;
            z-index: 100;
            text-transform: none;
            padding: 0 spacing.$spacing-base;
            top: -1px;
            bottom: -1px;
            color: inherit;
            border: inherit;
            background-color: inherit;
            box-shadow: inherit;
        }
    }
    &[data-tooltip] {
        &:before {
            content: attr(data-tooltip);
            transform-origin: left center;
            left: 100%;
        }
    }
    &[data-tooltip-left] {
        &:before {
            content: attr(data-tooltip-left);
            transform-origin: right center;
            right: 100%;
        }
    }
}

@mixin button($background-color, $background-color-hover) {
    &:not(.button-disabled):not([disabled]) {
        background-color: $background-color;
    }
    &:not(.button-disabled):not([disabled]):hover {
        background-color: $background-color-hover;
        cursor: pointer;
    }
}

.button {
    @include button(colours.$primary, colours.$primary-variant);
    display: inline-block;
    color: white;
    font-weight: bold;
    line-height: variables.$button-height;
    padding-left: spacing.$spacing-base;
    padding-right: spacing.$spacing-base;
    box-shadow: variables.$input-box-shadow;
    transition: all 0.25s linear;
    text-transform: uppercase;
    font-size: variables.$font-size-small;
    text-align: center;
    border-radius: variables.$border-radius;
    cursor: pointer;

    &[disabled],
    &-disabled {
        cursor: not-allowed;
        box-shadow: none;
        background-color: darken(colours.$disabled, 10%);
        color: darken(colours.$disabled-color, 10%);
    }

    &--outline[disabled],
    &--outline.button-disabled {
        background-color: transparent;
        color: lighten(colours.$flat-concrete, 25%);
        border: 1px solid rgba(colours.$flat-concrete, 0.2);
    }

    &:not([disabled]):not(&-disabled):hover:active {
        transition: none;
        box-shadow: none;
        background-color: colours.$color;
        border-color: colours.$color;
    }

    svg {
        font-weight: normal;
        margin-right: spacing.$spacing-base;
    }

    &--positive {
        @include button(colours.$positive, colours.$positive-variant);
    }

    &--negative {
        @include button(colours.$negative, colours.$negative-variant);
    }

    &--neutral {
        @include button(colours.$neutral, colours.$neutral-variant);
    }

    // Account for border width.
    &--outline {
        line-height: variables.$button-height - 2px;
    }

    &--icon {
        text-align: center;
        width: variables.$button-height;
        padding: 0;
        font-size: 18px;

        svg {
            margin-right: 0;
        }
    }

    &--small {
        line-height: variables.$button-small-height;
    }

    &--small.button--icon {
        width: variables.$button-small-height;
        font-size: variables.$font-size-small;
    }
}

.button--outline {
    @include outline-button(colours.$primary);
}

.button--positive.button--outline {
    @include outline-button(colours.$positive);
}

.button--negative.button--outline {
    @include outline-button(colours.$negative);
}

.button--neutral.button--outline {
    @include outline-button(colours.$neutral);
}

button {
    border: none;
    height: variables.$button-height;

    &.button--small {
        height: variables.$button-small-height;
    }

    padding-top: 0;
    padding-bottom: 0;
    &:focus {
        outline: none;
    }
}

tbody {
    .button--outline,
    button {
        height: variables.$table-button-height;
        line-height: variables.$table-button-height;
    }

    form {
        .button--outline,
        button {
            height: variables.$button-height;
            line-height: variables.$button-height;
        }
    }
}
