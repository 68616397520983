@use "styles/theme";
@use "styles/spacing";

.email-participants {
    padding-right: spacing.$spacing-small;

    @include theme.theme {
        color: darken(theme.get(label), 15%);
    }

    &__sender {
        margin-bottom: spacing.$spacing-xx-small;
    }

    &__type {
        font-weight: bold;
        white-space: nowrap;
    }

    &__emails {
        padding-left: spacing.$spacing-xx-small;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
    }

    &__recipients {
        display: table;
        &__list {
            display: table-row;
        }
        .email-participants__type,
        .email-participants__emails {
            display: table-cell;
        }
    }
}
