@use "styles/theme";
@use "styles/spacing";
@use "styles/root-variables" as *;
@use "styles/variables";

.fullscreen-query-history-thread {
    height: 100%;

    .query-history-thread {
        &__content {
            align-self: center;
            width: 100%;
            max-width: 75vw;
            margin: spacing.$spacing-xx-large spacing.$spacing-xxx-large;
            padding: spacing.$spacing-x-large;
            @include theme.theme {
                border: 1px solid theme.get(divider);
                background-color: theme.get(background);
            }
            border-radius: 5px;
        }

        .query-history-header {
            justify-content: space-between;
            height: var(--HEADER-HEIGHT);
            @include theme.theme {
                border-bottom: 1px solid theme.get(divider);
            }
            &__title {
                padding-left: variables.$page-spacing;
            }

            .button--split {
                margin-right: spacing.$spacing-large;
            }
        }
    }
}
