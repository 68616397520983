@use "styles/theme";

.interaction-icon {
    &--chat {
        --fa-primary-opacity: 0.65;
        --fa-secondary-opacity: 1;
        @include theme.theme {
            --fa-primary-color: #{theme.get(primary)};
        }
    }

    &--robot {
        --fa-primary-opacity: 0.9;
        --fa-secondary-opacity: 0.6;
    }

    &--email-in,
    &--email-out {
        --fa-secondary-opacity: 0.85;
    }

    &--email-in {
        @include theme.theme {
            --fa-primary-color: #{theme.get(negative)};
        }
    }

    &--email-out {
        @include theme.theme {
            --fa-primary-color: #{theme.get(positive)};
        }
    }
}
