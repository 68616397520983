@use "colours";

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 999;
    background-color: colours.$flat-alizarin;
    box-shadow: 1px 0 3px colours.$flat-alizarin;
}
