////
/// Named colours used throughout all EdgeTier products
/// @group Colours
////

/// Greens
$flat-turquoise: #1abc9c;
$flat-green-sea: #16a085;
$flat-emerald: #2ecc71;
$flat-nephritis: #27ae60;

/// Blues
$flat-peter-river: #3498db;
$flat-belize-hole: #2980b9;
$flat-wet-asphalt: #34495e;
$flat-midnight-blue: #2c3e50;

/// Purples
$flat-amethyst: #9b59b6;
$flat-wisteria: #8e44ad;

/// Yellows
$flat-sunflower: #f1c40f;

/// Oranges
$flat-orange: #f39c12;
$flat-carrot: #e67e22;
$flat-pumpkin: #d35400;

/// Reds
$flat-alizarin: #e74c3c;
$flat-pomegranate: #c0392b;

/// Grays
$flat-clouds: #ecf0f1;
$flat-silver: #bdc3c7;
$flat-concrete: #95a5a6;
$flat-asbestos: #7f8c8d;
