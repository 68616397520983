/**
 * Styles for React Select. The library uses a JS in CSS library so everything has to be overrided with important rules.
 */
@use "spacing";
@use "theme";
@use "variables";

.select__trigger {
    border-radius: variables.$border-radius;
    @include theme.theme {
        background-color: theme.get(background);
    }
}

.react-select {
    .field &-container {
        box-shadow: none;
        border-radius: none;
    }

    &-container {
        box-shadow: variables.$input-box-shadow;
        border-radius: variables.$border-radius;

        @include theme.theme {
            background-color: theme.get(background);
        }
    }

    &__placeholder {
        @include theme.theme {
            color: theme.get(label) !important;
        }
    }

    &__control {
        &,
        &--is-focussed {
            border: none !important;
            box-shadow: none !important;
            background-color: transparent !important;
        }

        &--is-disabled {
            background-color: transparent !important;
        }
    }

    &__single-value {
        line-height: variables.$input-line-height;
    }

    &__menu {
        margin-top: 1px !important;
        border-radius: 0 !important;
        box-shadow: 0 2px 2px rgba(black, 0.1), 0 5px 2px -2px rgba(black, 0.1) !important;
        line-height: variables.$line-height-base;
    }

    &__option {
        padding: spacing.$spacing-base variables.$field-x-padding !important;
        transition: background-color 150ms ease-out, color 150ms ease-out;

        &--is-focused {
            cursor: pointer !important;

            @include theme.theme {
                color: theme.get(background) !important;
                background-color: theme.get(positive) !important;
            }
        }

        &--is-selected {
            @include theme.theme {
                color: theme.get(positive) !important;
                background-color: theme.get(background) !important;
            }

            &:hover {
                @include theme.theme {
                    color: theme.get(negative) !important;
                }
            }
        }
    }

    &__value-container {
        padding: 0 variables.$field-x-padding !important;
        white-space: nowrap;
        overflow: hidden;

        > div:last-of-type {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    &__indicator-separator {
        @include theme.theme {
            background-color: theme.get(background-secondary);
        }
    }

    &__multi-value {
        margin: 0 spacing.$spacing-xxx-small 0 0 !important;

        &__label {
            padding: spacing.$spacing-x-small spacing.$spacing-small !important;
        }

        &__remove {
            transition: all 150ms ease-out !important;
            cursor: pointer;
            border-radius: 0 !important;

            &:hover {
                @include theme.theme {
                    color: theme.get(negative) !important;
                    background-color: theme.get(background-secondary);
                }
            }

            @include theme.theme {
                color: theme.get(primary);
                border-left: 1px solid theme.get(background-tertiary);
            }
        }

        @include theme.theme {
            background-color: transparent !important;
            border: 1px solid theme.get(background-tertiary);
        }
    }
}
